import { z } from 'zod';

const datesSchema = z.union([
  z.object({ dueDate: z.string(), completionDate: z.string() }),
  z.object({ dueDate: z.string(), completionDate: z.null() }),
  z.object({ dueDate: z.null(), completionDate: z.string() })
]);

export const getUserTransactionsResponseSchema = z.array(
  z
    .object({
      id: z.string(),
      type: z.enum(['PAYBACK', 'DEPOSIT', 'WITHDRAW', 'REFUND', 'SETUP_FEE']),
      status: z.enum([
        'COMPLETE',
        'INCOMPLETE',
        'CHARGE',
        'IN_EXTENDED_PAYMENT_PERIOD',
        'OVERDUE',
        'PAYMENT_FREE_MONTH'
      ]),
      amount: z.number(),
      items: z.array(
        z.object({
          product: z.enum(['CREDIT', 'DEBT_CONSOLIDATION', 'PAYMENT_PROTECTION_INSURANCE', 'ID_PROTECT']),
          details: z.array(
            z.object({
              type: z.enum([
                'PRINCIPAL',
                'INTEREST',
                'OVERDUE_INTEREST',
                'WITHDRAWAL_FEE',
                'OVERDUE_FEE',
                'DEBT_COLLECTION_FEE',
                'DIRECT_DEBIT_MISSING_FEE',
                'REMIT',
                'UNKNOWN',
                'TERMINATION',
                'CAMPAIGN_DISCOUNT',
                'PREMIUM',
                'COMPOUNDED_PRINCIPAL',
                'SETUP_FEE',
                'INSTALLMENT_FEE'
              ]),
              amount: z.number()
            })
          )
        })
      )
    })
    .and(datesSchema)
);
